// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import {useForm, ValidationError} from '@formspree/react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

function ContactFormSpree() {
    const [state, handleSubmit] = useForm("xeqdeavj");
    if (state.succeeded) {
        return <p className={'display-3 text-center'}>Děkujeme. Vaše zpráva byla úspěšně odeslána.</p>;
    }
    return (
    <form onSubmit={handleSubmit} className={"h-100"}>

            <div className={"row contactFormWrapper"}>
                <div className={"col-sm-12"}>
                    <div className={"form-group"}>
                        <input type={"text"} name={"name"} className="form-control" id="name"
                               placeholder="Jméno / Firma"/>
                        <ValidationError
                            prefix="Jméno"
                            field="name"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className="form-group">
                        <input type="email" name="email" className="form-control" id="email"
                               placeholder="E-Mail"/>
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className="form-group">
                        <input type="phone" name="phone" className="form-control" id="phone"
                               placeholder="Telefon"/>
                        <ValidationError
                            prefix="Telefon"
                            field="phone"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className={"col-sm-12"}>
                    <div className="form-group">
                     <textarea placeholder="Zpráva" className="form-control" id="message" name={"message"}
                               rows="7">
                     </textarea>
                    </div>
                </div>


                <div className={"col-sm-12 mt-lg-auto"}>
                    <button type="submit" className="btn btn-secondary" disabled={state.submitting}>
                        Odeslat <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
            </div>


        </form>
    )
        ;

    //
    // <form onSubmit={handleSubmit}>
    //     <label htmlFor="email">
    //         Email Address
    //     </label>
    //     <input
    //         id="email"
    //         type="email"
    //         name="email"
    //     />
    //     <ValidationError
    //         prefix="Email"
    //         field="email"
    //         errors={state.errors}
    //     />
    //     <textarea
    //         id="message"
    //         name="message"
    //     />
    //     <ValidationError
    //         prefix="Message"
    //         field="message"
    //         errors={state.errors}
    //     />
    //     <button type="submit" disabled={state.submitting}>
    //         Submit
    //     </button>
    // </form>
// )
//     ;
}

export default ContactFormSpree;