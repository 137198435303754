import React from 'react'
import {StaticImage} from "gatsby-plugin-image";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


import {
    faArrowLeft,
    faArrowRight

} from "@fortawesome/free-solid-svg-icons";

const settings = {

    lazyLoad: true,
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 600,
    arrows: true,
    autoplay: true,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
        {
            breakpoint: 10024,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
        ]
}

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block", fontSize: "30px", right: "-18px", zIndex: "+1"}}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faArrowRight} className="text-primary"/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block", fontSize: "30px", left: "-18px", zIndex: "+1" }}
            onClick={onClick}
        >  <FontAwesomeIcon icon={faArrowLeft} className="text-primary"/></div>
    );
}

const CarouselReference = () => (
    <div className={"mt-5 mb-5"}>
    <div className="container">
        <h3 className="text-center mt-5 mb-4 fw-normal referenceHeadline">Mezi naše klienty patří</h3>
        <Slider {...settings} className="reference">

            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/srba.png" alt={"SRBA SERVIS s.r.o."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/duha.png" alt={"Společnost DUHA, z.ú."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/greif.png"
                              alt={"Greif-akustika, s.r.o."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/vubp.png"
                              alt={"Výzkumný ústav bezpečnosti práce, v. v. i."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/realism.png"
                              alt={"REALISM Development s.r.o."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/promotron.png"
                              alt={"PromoTron Solutions a.s."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/kralicek.png" alt={"KRÁLÍČEK s.r.o."}/>
            </div>
            <div className='ps-2 pe-2 ps-md-5 pe-md-5'>
                <StaticImage className="w-100"
                              src="../../assets/custom-style/images/reference/lehotsky.png" alt={"Lehotsky Capital"}/>
            </div>

        </Slider>

    </div>
    </div>
)

export default CarouselReference
