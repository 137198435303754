import React from "react"
import Layout from "../components/Layout/Layout"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

// import TeamViewer from "../assets/custom-style/images/icons/ikona-teamviewer.png"
// import Digi from "../assets/custom-style/images/icons/ikona-digi.png"
// import Kontakt from "../assets/custom-style/images/icons/ikona-kontakt.png"
import CarouselReference from "../components/Components/CarouselReference";
import Kontakt from "../components/Layout/Contact";

export default function Home() {


    return (
        <Layout>
            <div className="homepage">
                <div className="headerBluePic">
                    <div className="headerBlue_content">
                        <div className="container">
                            <div className="pt-2 pt-md-5 pb-2">
                                <h1 className={"text-center text-md-start text-white"}>Máte problém s IT?
                                    <br/>
                                    Digi Office, s.r.o. má pro Vás řešení.</h1>
                            </div>
                            <div className="picCards mt-md-5">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <Link to={'/teamviewer'} href="#" className="boxLink">
                                            <div className="card grow h-100 text-center p-0 pt-md-2 pb-md-2">
                                                <div className="card-body mb-2 mb-md-3">
                                                    <div className="card-pic mx-auto">
                                                        <div className={"d-none d-md-block px-0 px-xl-3 px-xxl-5"}>
                                                            <StaticImage imgClassName={""}
                                                                         src="../assets/custom-style/images/icons/ikona-teamviewer.png"
                                                                         alt="TeamViewer"
                                                            />
                                                        </div>
                                                        <div className={"d-block d-md-none"}>
                                                            <StaticImage imgClassName={"spravait"} style={{width: 32 + 'px'}}
                                                                         src="../assets/custom-style/images/icons/ikona-teamviewer.png"
                                                                         alt="TeamViewer"
                                                            />
                                                        </div>

                                                    </div>
                                                    <h4 className="card-title">Stáhnout TeamViewer</h4>
                                                    <h5 className="card-subtitle"></h5>
                                                    <p className="card-text d-none d-sm-block pb-2">Po stažení a
                                                        spuštění TeamVieweru se náš technik připojí přímo k Vašemu
                                                        počítači a pomůže Vám s řešením.</p>
                                                </div>
                                                <div className={'box-link-down'}>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card grow h-100 text-center p-0 pt-md-2 pb-md-2">
                                            <a href={'https://digioffice.cz/'} target={"_blank"} className="boxLink">
                                                <div className="card-body  mb-2 mb-md-3">
                                                    <div className="card-pic mx-auto">
                                                        <div className={"d-none d-md-block px-0 px-xl-3 px-xxl-5"}>
                                                            <StaticImage imgClassName={"cloudovesluzby"}
                                                                         src="../assets/custom-style/images/icons/ikona-digi.png"
                                                                         alt="Nabídka služeb"
                                                            />
                                                        </div>
                                                        <div className={"d-block d-md-none"}>
                                                            <StaticImage imgClassName={"cloudovesluzby"} style={{width: 32 + 'px'}}
                                                                         src="../assets/custom-style/images/icons/ikona-digi.png"
                                                                         alt="Nabídka služeb"
                                                            />
                                                        </div>
                                                    </div>
                                                    <h4 className="card-title">Nabídka služeb</h4>
                                                    <h5 className="card-subtitle"></h5>
                                                    <p className="card-text d-none d-sm-block pb-2">Přejděte na naše
                                                        hlavní webové stránky <a href={'https://digioffice.cz/'}
                                                                                 target={"_blank"}>www.digioffice.cz</a> a
                                                        prohlédněte si
                                                        kompletní nabídku našich služeb.</p>
                                                </div>
                                                <div className={'box-link-down'}>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <a href={'https://digioffice.cz//kontakt'} target={"_blank"} className="boxLink">
                                            <div className="card grow h-100 text-center p-0 pt-md-2 pb-md-2">
                                                <div className="card-body mb-2 mb-md-3">
                                                    <div className="card-pic mx-auto">

                                                        <div className={"d-none d-md-block px-0 px-xl-3 px-xxl-5"}>
                                                            <StaticImage imgClassName={"itmanazer"}
                                                                         src="../assets/custom-style/images/icons/ikona-kontakt.png"
                                                                         alt="Kontaktujte nás"/>

                                                        </div>
                                                        <div className={"d-block d-md-none"}>
                                                            <StaticImage imgClassName={"itmanazer"} style={{width: 32 + 'px'}}
                                                                         src="../assets/custom-style/images/icons/ikona-kontakt.png"
                                                                         alt="Kontaktujte nás"/>

                                                        </div>
                                                    </div>
                                                    <h4 className="card-title">Kontaktujte nás</h4>
                                                    <h5 className="card-subtitle"></h5>
                                                    <p className="card-text d-none d-sm-block pb-2">V případě
                                                        technických problémů volejte naší podporu na 608 773 883 nebo
                                                        pište na <a
                                                            href={"mailto:podpora@digioffice.cz"}>podpora@digioffice.cz</a>
                                                    </p>
                                                </div>
                                                <div className={'box-link-down'}>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container text-center text-md-start pt-md-3 pt-5">
                    <div className="row">
                        <div className="col-md">
                            <h2 className=" heading-border-bottom-blue mb-5">Kdo jsme</h2>
                            <p>Digi Office je Pražská <strong>IT firma s 15 letou historií</strong> a praktickými zkušenostmi. Děláme IT
                                a technologie pro malé a středně velké firmy a organizace.</p>
                            <p>Přinášíme Vám řešení pro práci s informacemi, sdílení, komunikaci a spolupráci, navíc vše
                                zabezpečíme a nastavíme tak, abyste ho mohli využívat v práci, doma i na cestách.</p>
                            <p> <strong>Dokážeme se o Vás postarat komplexně formou Outsourcingu,</strong> tedy převzetím správy celého
                                Vašeho IT.</p>
                            <p>Více se dozvíte na našem webu <a href="https://digioffice.cz/" target={"_blank"}>Digioffice.cz</a></p>
                        </div>

                        <div className="col-md text-center mb-4 pt-md-5 pt-xl-0 mt-md-5 mt-xl-0">
                            <StaticImage imgClassName={"aboutus"} className="w-75"
                                         alt="DigiOffice tým"
                                         width={800}
                                         src="../assets/custom-style/images/digi/_NG_8377_ACR.jpg"/>
                        </div>
                    </div>
                    <div className="px-3 px-md-0">
                        <CarouselReference/>
                    </div>
                </div>
            </div>
            <Kontakt/>
        </Layout>
    );
}