import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faMapMarker,
    faPhone,
    faClock,
    faMobileAlt,
    faEnvelopeOpen,
    faAngleRight,
    faEnvelopeOpenText,
    faEnvelopeSquare
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";
import Nadpis from "../Layout/Nadpis";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {StaticImage} from "gatsby-plugin-image";
import ContactFormSpree from "../Components/ContactFormSpree";

const Kontakt = () => (
        <section className={"kontakt"}>
            <div className="contactBlue">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md text-center text-md-start">
                            <h4 className="mb-5 heading-border-bottom-white ">Staňte se<br/>
                                naším klientem</h4>
                            <address className="contactBlue__block-with-icon mb-5">
                                <StaticImage width={40} imgClassName={"contactBlue__block-icon d-inline-block"} src="../../assets/custom-style/images/icons/phone.webp" alt={"Zavolejte nám"} />
                                <a href="tel:+420603773883" className="d-inline-block ms-4">+420 603 773 883</a>

                            </address>
                            <address className="contactBlue__block-with-icon mb-5">
                                <StaticImage alt={"Napište nám"} width={40} imgClassName={"contactBlue__block-icon d-inline-block"} src="../../assets/custom-style/images/icons/mail.webp" />
                                <a href="mailto:info@digioffice.cz" className="d-inline-block ms-4">info@digioffice.cz</a>

                            </address>

                            
                            <a href={'https://digioffice.cz/kontakt/'} target={"_blank"}  type="button" className="btn button-rounded-white rounded-pill d-none d-md-inline-block">Zobrazit všechny
                                kontakty
                            </a>

                        </div>
                        <div className="col-12 col-md">
                            <ContactFormSpree />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
;

export default Kontakt